import React from 'react';
import { Box, CircularProgress, Typography, styled } from '@mui/material';

const CircularProgressWithLabel = ({ value, label, size = '5rem' }) => (
  <ProgressWrapper>
    <CircularProgress size={size} variant='determinate' value={value} />
    <ProgressLabel>
      <Typography variant='caption' component='div' color='text.secondary'>
        {label}
      </Typography>
    </ProgressLabel>
  </ProgressWrapper>
);

export default CircularProgressWithLabel;

const ProgressWrapper = styled(Box)({
  position: 'relative',
  display: 'inline-flex',
});

const ProgressLabel = styled(Box)({
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
