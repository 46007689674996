import React from 'react';
import { Button, CircularProgress, styled } from '@mui/material';

const CustomizedButton = ({ loading = 0, startIcon, endIcon, children, ...rest }) => (
  <StyledButton
    startIcon={!loading && startIcon}
    endIcon={!loading && endIcon}
    loading={loading ? 1 : 0}
    disabled={Boolean(loading)}
    {...rest}>
    {loading ? <CircularProgress sx={{ color: 'white' }} size={'1.2rem'} /> : children}
  </StyledButton>
);

const StyledButton = styled(Button)(({ theme, loading, variant = 'primary' }) => ({
  textTransform: 'capitalize',
  color: variant === 'secondary' ? theme.palette.primary.main : theme.palette.common.white,
  border: variant === 'secondary' ? `1px solid ${theme.palette.primary.main}` : 'none',
  boxShadow: theme.boxShadow.primary,
  borderRadius: theme.borderRadius.small,
  backgroundColor:
    variant === 'secondary' ? theme.palette.common.white : theme.palette.primary.main,
  padding: loading ? theme.spacing(1.5, 6) : theme.spacing(1, 3.5),

  '&:hover': {
    backgroundColor:
      variant === 'secondary' ? theme.palette.grey[100] : theme.palette.primary.light,
  },
}));

export default CustomizedButton;
