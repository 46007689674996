import React from 'react';
import { Link } from 'gatsby';
import { Stack, Typography, styled } from '@mui/material';

import { Grid, Icon } from 'ui';
import logoSmallImg from 'images/favicon.png';

const Footer = () => (
  <StyledFooter>
    <StyledGrid columns={4}>
      <Stack gap={2}>
        <Typography variant='h5'>quick links</Typography>
        <Stack direction={{ sm: 'row', md: 'column' }} columnGap={3}>
          <Link to='/about'>about</Link>
          <Link to='/courses'>courses</Link>
          <Link to='/privacy-policy'>privacy policy</Link>
        </Stack>
      </Stack>

      <Stack gap={2}>
        <Typography variant='h5'>contact us</Typography>
        <Stack direction='row' gap={2} sx={{ justifyContent: { md: 'flex-start', xs: 'center' } }}>
          <a
            href='https://hi-in.facebook.com/IPTutorialsVadodara/'
            target='_blank'
            rel='noreferrer'>
            <Icon name='Facebook' />
          </a>
          <a href='https://www.instagram.com/iptutorials5' target='_blank' rel='noreferrer'>
            <Icon name='Instagram' />
          </a>
          <a href='https://in.linkedin.com/company/ip-tutorials' target='_blank' rel='noreferrer'>
            <Icon name='Linkedin' />
          </a>
          <a href='https://www.youtube.com/@iptutorials8432' target='_blank' rel='noreferrer'>
            <Icon name='Youtube' />
          </a>
        </Stack>
      </Stack>

      <Stack gap={2}>
        <div>
          <Typography variant='h5'>email address</Typography>
          <Typography mt={1} variant='body2' textTransform='lowercase'>
            <a href='mailto:iptutorials5@gmail.com'>iptutorials5@gmail.com</a>
          </Typography>
        </div>

        <div>
          <Typography variant='h5'>mobile number</Typography>
          <Typography mt={1} variant='body2'>
            <a href='tel:+919825523992'>+91 - 98255 23992</a>
          </Typography>
          <Typography variant='body2'>
            <a href='tel:+9109106615552'>+91 - 9106 615552</a>
          </Typography>
        </div>
      </Stack>

      <FootLogo src={logoSmallImg} alt='LOGO' />
    </StyledGrid>

    <Typography mt={5} variant='body1' textAlign='center'>
      copyright &copy; {new Date().getFullYear()} | all rights reserved.
    </Typography>
    <Typography variant='subtitle2' textAlign='center'>
      developed by FIBO 1618.
    </Typography>
  </StyledFooter>
);

export default Footer;

const StyledFooter = styled('footer')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  padding: theme.spacing(8, 10, 3),
  color: theme.palette.common.white,
  textTransform: 'capitalize',

  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
    fontSize: '14px',
  },
}));

const FootLogo = styled('img')(({ theme }) => ({
  height: '10rem',
  width: '10rem',

  [theme.breakpoints.down('md')]: {
    order: -1,
    justifySelf: 'center',
  },
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  gap: theme.spacing(2),
  justifyItems: 'center',

  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(4),
  },
}));
