import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      lighter: 'rgba(0, 82, 122, .3)',
      main: '#00527A',
    },
  },

  // https://stackoverflow.com/questions/52472372/responsive-typography-in-mui
  typography: {
    allVariants: {
      fontFamily: `'Work Sans', sans-serif`,
    },
    body1: {
      fontSize: '1.25rem',
      lineHeight: 2,
    },
  },

  border: {
    greyish: '1px solid #EAEAEA',
  },

  borderRadius: {
    small: '11px',
    medium: '2.25rem',
  },

  boxShadow: {
    primary: 'inset 7px 7px 8px #00000029, 10px 10px 8px #00000029',
    secondary: 'inset 8px 12px 8px #00000029, -7px -10px 8px #00000029',
    tertiary: '7px 15px 9px #00000029',
    singleLayer: '10px 10px 8px #00000029',
  },

  utils: {
    createAbstractLine: (css = {}) => ({
      content: '""',
      position: 'absolute',
      height: '3px',
      borderRadius: '.5rem',
      backgroundColor: theme.palette.primary.main,
      ...css,
    }),
  },
});

export default theme;
