import React from 'react';
import { styled } from '@mui/material';

import Navigation from './navigation';
import Footer from './footer';
import { Button } from 'ui';
import whatsappImg from 'images/whatsapp.webp';

const Layout = ({ children }) => (
  <>
    <Navigation />
    <Main>{children}</Main>
    <WhatsappBtn
      href={`https://api.whatsapp.com/send?phone=+919825523992&text=${encodeURIComponent(
        'Hi, I would like to know more about IP-Tutorials.'
      )}`}>
      <img src={whatsappImg} alt='WhatsApp' />
    </WhatsappBtn>
    <Footer />
  </>
);

export default Layout;

const WhatsappBtn = styled('a')(({ theme }) => ({
  position: 'fixed',
  zIndex: 999,
  bottom: '1rem',
  right: '1rem',
  borderRadius: '100%',
  padding: 0,
  cursor: 'pointer',
  backgroundColor: 'transparent',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '& > img': {
    height: '4rem',
    width: '4rem',
    objectFit: 'cover',
  },
}));

const Main = styled('main')(({ theme }) => ({
  paddingTop: theme.spacing(10),
  minHeight: '100vh',

  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(2),
  },
}));
