import React from 'react';
import { Container, styled } from '@mui/material';

const Section = ({ children, maxWidth = 'lg', ...rest }) => (
  <StyledSection {...rest}>
    <Container maxWidth={maxWidth}>{children}</Container>
  </StyledSection>
);

export default Section;

const StyledSection = styled('section')(({ theme, greyish }) => ({
  padding: theme.spacing(10, 5),
  backgroundColor: greyish
    ? theme.palette.grey[100]
    : theme.palette.common.white,

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(5, 1),

    '&:first-of-type': {
      paddingTop: theme.spacing(7),
    },
  },
}));
