import React from 'react';
import { Typography, styled } from '@mui/material';

const Heading = ({
  children,
  variant = 'primary',
  abstractLine = false,
  abstractLineOnlyOnHover = false,
  expandableAbstractLine = false,
  ...rest
}) => {
  switch (variant) {
    case 'primary':
      return (
        <MainHeading
          className={`${abstractLine ? 'abstract-line' : ''} ${
            abstractLineOnlyOnHover ? 'abstract-line-zero' : ''
          } ${expandableAbstractLine ? 'expandable-abstract-line' : ''}`}
          variant='h3'
          {...rest}>
          {children}
        </MainHeading>
      );

    case 'secondary':
      return (
        <BaseTypography
          className={`${abstractLine ? 'abstract-line' : ''} ${
            abstractLineOnlyOnHover ? 'abstract-line-zero' : ''
          } ${expandableAbstractLine ? 'expandable-abstract-line' : ''}`}
          variant='h6'
          {...rest}>
          {children}
        </BaseTypography>
      );

    default:
      return (
        <Typography variant='h6' {...rest}>
          {children}
        </Typography>
      );
  }
};

export default Heading;

const BaseTypography = styled(Typography)(({ theme }) => ({
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.primary.main,
}));

const MainHeading = styled(BaseTypography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,

  [theme.breakpoints.down('md')]: {
    marginTop: '5rem',
    width: '75%',
  },

  [theme.breakpoints.down('sm')]: {
    width: '100%',
    fontSize: '2rem',
  },
}));
