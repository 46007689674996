import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { Box } from '@mui/material';

import MobileStepper from './mobile-stepper';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Carousel = ({ children, showControls = true, interval = 3000, defaultStep = 0, ...rest }) => {
  const [activeStep, setActiveStep] = React.useState(defaultStep);
  const maxSteps = children?.length;

  // const handleNext = () => {
  //   setActiveStep(prevActiveStep =>
  //     prevActiveStep === maxSteps - 1 ? 0 : prevActiveStep + 1
  //   );
  // };

  // const handleBack = () => {
  //   setActiveStep(prevActiveStep =>
  //     prevActiveStep === 0 ? maxSteps - 1 : prevActiveStep - 1
  //   );
  // };

  const handleStepChange = step => {
    setActiveStep(step);
  };

  if (!maxSteps) return <></>;

  return (
    <Box sx={{ position: 'relative' }}>
      <AutoPlaySwipeableViews
        interval={interval}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents>
        {children.map((child, index) => (
          <Box {...rest} key={index}>
            {Math.abs(activeStep - index) <= 2 ? child : null}
          </Box>
        ))}
      </AutoPlaySwipeableViews>

      {showControls && (
        <MobileStepper
          steps={maxSteps}
          activeStep={activeStep}
          onStepClick={currentStep => setActiveStep(currentStep)}
        />
      )}
    </Box>
  );
};

export default Carousel;
